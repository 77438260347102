<template>
  <div class="writeemailmain">
    <div class="writeemailleft" v-loading="sendloading">
      <div style="display: flex; justify-content: space-between">
        <div>
          <div class="Receiptertext">
            <span>
              <!-- 收件人 -->
              {{ $t("label.emailobject.receive") }}
            </span>
          </div>
          <div
            v-if="ccstatus"
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              margin-top: 15px;
              white-space: nowrap;
              height: 40px;
              line-height: 40px;
            "
          >
            <span>
              <!-- 抄送 -->
              {{ $t("label.emailobject.cc") }}
            </span>
          </div>
          <div
            v-if="bccstatus"
            style="
              display: flex;
              align-items: center;
              white-space: nowrap;
              margin-top: 15px;
              white-space: nowrap;
              height: 40px;
              line-height: 40px;
            "
          >
            <span>
              <!-- 密送 -->
              {{ $t("label.emailobject.bcc") }}
            </span>
          </div>
          <div
            style="
              white-space: nowrap;
              margin-top: 15px;
              height: 40px;
              line-height: 40px;
            "
          >
            <span>
              <!-- 主题 -->
              {{ $t("label.ems.subject") }}
            </span>
          </div>
          <div style="white-space: nowrap; margin-top: 15px">
            <span>
              <!-- 正文 -->
              {{ $t("label.email.body") }}
            </span>
          </div>
        </div>
        <div style="width: 100%; margin-left: 10px">
          <div class="Receipter">
            <div class="my-multiselect" style="width: 100%">
              <el-select
                ref="Receipter"
                v-model="seahcvalue"
                @keyup.enter.native="addmytag"
                @focus="addtagtest('seahcvalue', 'Receipter')"
                multiple
                filterable
                remote
                placeholder=""
                :remote-method="asyncFind"
              >
                <el-option
                  v-for="item in countries"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                  <span style="float: left">{{ item.value }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.name
                  }}</span>
                </el-option>
              </el-select>
            </div>
            <div class="bcc">
              <span @click="ccstatus = true">
                <!-- 抄送 -->
                {{ $t("label.email.cc") }}
              </span>
              <span @click="bccstatus = true">
                <!-- 密送 -->
                {{ $t("vue_label_commonobjects_detail_bcc") }}
              </span>
            </div>
          </div>
          <div
            v-if="ccstatus"
            style="display: flex; align-items: center"
            class="cc"
          >
            <div class="my-multiselect" style="width: 100%; margin-top: 15px">
              <el-select
                ref="cc"
                v-model="cc"
                @keyup.enter.native="addmytag1"
                @focus="addtagtest1('cc', 'cc')"
                multiple
                filterable
                remote
                placeholder=""
                :remote-method="asyncFind1"
              >
                <el-option
                  v-for="item in countries1"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                  <span style="float: left">{{ item.value }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.name
                  }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div
            v-if="bccstatus"
            style="display: flex; align-items: center"
            class="bccemail"
          >
            <div class="my-multiselect" style="width: 100%; margin-top: 15px">
              <el-select
                ref="bcc"
                v-model="bcc"
                @keyup.enter.native="addmytag2"
                @focus="addtagtest2('bcc', 'bcc')"
                multiple
                filterable
                remote
                placeholder=""
                :remote-method="asyncFind2"
              >
                <el-option
                  v-for="item in countries2"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                >
                  <span style="float: left">{{ item.value }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.name
                  }}</span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="themename">
            <!-- 请填写主题 -->
            <el-input
              v-model="theme"
              :placeholder="$t('label.weixin.input.subject')"
            ></el-input>
          </div>
          <div class="editbox">
            <div style="width: 100%" v-if="isText == 'false'">
              <wangeditor ref="wangeditor" @editorContent="editorContent">
              </wangeditor>
            </div>
            <div v-else style="width: 100%; height: 300px">
              <el-input type="textarea" :rows="8" v-model="textarea"></el-input>
            </div>
          </div>
          <div class="files">
            <ul>
              <li v-for="(item, index) in enclosurearr" :key="item.id">
                <div class="enclosurebox">
                  <div class="enclosureicon">
                    <svg class="icon" aria-hidden="true">
                      <use href="#icon-file1"></use>
                    </svg>
                  </div>
                  <span>{{ item.name }}</span>
                </div>
                <i class="el-icon-close" @click="deletefile(index)"></i>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="toolbar">
        <ul>
          <li @click="tabeyescolor">
            <el-tooltip
              class="item"
              effect="dark"
              :content="eyes.title"
              placement="top"
            >
              <div class="toolbaricon">
                <svg class="icon" aria-hidden="true">
                  <use
                    :href="
                      eyes.istabimg == true ? eyes.changeimgurl : eyes.imgurl
                    "
                  ></use>
                </svg>
              </div>
            </el-tooltip>
          </li>
          <li
            v-for="(item, index) in toolbarOption"
            :key="index"
            @click="tabtoolbar(item.name)"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.title"
              placement="top"
            >
              <div class="toolbaricon">
                <svg class="icon" aria-hidden="true">
                  <use
                    :href="
                      item.istabimg == true ? item.changeimgurl : item.imgurl
                    "
                  ></use>
                </svg>
              </div>
            </el-tooltip>
          </li>
        </ul>
        <!-- 添加附件 -->
        <div class="addfile">
          <addFiles
            ref="atoLib"
            :isFromFileCenter="false"
            :dialogAddVisible="toolbarOption[0].istabimg"
            @close="closedailog"
            @addSubmit="addenclosure"
          ></addFiles>
        </div>
        <!-- 添加字段 -->
        <div>
          <addEmailFields
            :istabimg="toolbarOption[1].istabimg"
            @closedailogFields="closedailogFields"
            @insertFieldsMethod="insertFieldsMethod"
          ></addEmailFields>
        </div>
        <!-- 管理模板 -->
        <!-- <div v-if="toolbarOption[2].istabimg"> -->
        <!-- 更新模板 -->
        <!-- <el-dialog
            :title="$t('vue_label_email_update_template')"
            :visible.sync="toolbarOption[2].istabimg"
            width="45%"
          >
            <div>
              <updateTemplate
                :istabimg="toolbarOption[2].istabimg"
                :theme="theme"
                :editorText="editorText"
                @closeTemplate="closeTemplate"
              ></updateTemplate>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="toolbarOption[2].istabimg = false"> -->
        <!-- 取消 -->
        <!-- {{ $t("label.cancel") }}
              </el-button>
            </span>
          </el-dialog> -->
        <!-- </div> -->
        <!-- 签名 -->
        <emailSign v-if="toolbarOption[3].istabimg"></emailSign>
      </div>
      <div class="subbotton">
        <el-button
          size="small"
          style="margin-right: 10px"
          @click="sendEmail('0')"
        >
          <!-- 保存 -->
          {{ $t("label.save") }}
        </el-button>
        <div
          style="
            border: 1px solid #005fb2;
            height: 30px;
            line-height: 30px;
            background-color: #005fb2;
            padding: 0 10px;
            font-size: 10px;
            color: #fff;
            display: flex;
            align-items: center;
            border-radius: 3px;
            cursor: pointer;
          "
        >
          <span style="margin-right: 5px" @click="sendEmail('1')">
            <!-- 发送 -->
            {{ $t("label.send") }}
          </span>
          <el-dropdown trigger="click">
            <i class="el-icon-arrow-down" style="color: #fff"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="sendEmail('2')">
                <!-- 分别发送 -->
                {{ $t("label.emailobject.emailsend.sendindividually") }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <!-- 插入字段失败提示 -->
      <div>
        <!-- 提示 -->
        <el-dialog
          :title="$t('label.prompt')"
          :visible.sync="errdialog"
          width="40%"
        >
          <span>
            <!-- 当前邮件正文中插入的字段与已关联的记录不匹配，可能会造成取值失败。 -->
            {{ $t("label.emailobject.template.message2") }}
          </span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="errdialog = false">
              <!-- 放弃 -->
              {{ $t("label.emailobject.template.giveup") }}
            </el-button>
            <el-button type="primary" @click="Continueadd">
              <!-- 继续添加 -->
              {{ $t("label.emailobject.template.insert.continue") }}
            </el-button>
          </span>
        </el-dialog>
      </div>
    </div>
    <div class="writeemailright">
      <div>
        <span>
          <!-- 相关项 -->
          {{ $t("label.relateobj") }}
        </span>
        <relavent-select ref="relaventSelect"></relavent-select>
      </div>
    </div>
    <!--    新版邮件模板管理-->
    <email-template-front
      ref="emailTemplateFront"
      @deliverTemplate="deliverTemplate"
      :showRelateId="showRelateid"
    ></email-template-front>
  </div>
</template>

<script>
import relaventSelect from "@/components/Email/relaventSelect";
import emailSign from "@/components/Email/emailSign";
import addFiles from "../../components/AddFiles/AddFiles";
// import updateTemplate from "@/components/Email/updateTemplate";
import wangeditor from "../../components/emailEditor";
import addEmailFields from "@/components/Email/addEmailFields";
import * as Emailobject from "./api.js";
import emailTemplateFront from "@/components/Email/email-template-front";

export default {
  data() {
    return {
      //插入字段
      insertFields: "",
      theme: "",
      cc: [],
      bcc: [],
      ccstatus: false,
      bccstatus: false,
      errdialog: false,
      sendloading: false,
      editorText: "",
      Relateditems: "",
      personalemailmsg: null,
      seahcvalue: [],
      userList: [],
      queryvalue: "",
      queryvalue1: "",
      queryvalue2: "",
      emailSignval: "",
      checknum: 0,
      countries: [],
      countries1: [],
      countries2: [],
      enclosurearr: [],
      uploadJson: "/api/portal/editor/pic/upload", // 上传图片的URL
      eyes: {
        name: "eyes",
        // 邮件追踪
        title: this.$i18n.t("label.emailobject.trackemailopening"),
        istabimg: true,
        imgurl: "#icon-show",
        changeimgurl: "#icon-show_blue",
      },
      toolbarOption: [
        {
          name: "enclosure",
          // 添加附件
          title: this.$i18n.t("component_chatter_post_button_file"),
          istabimg: false,
          imgurl: "#icon-addassembly",
          changeimgurl: "#icon-addassemblyblue",
        },
        {
          name: "InsertField",
          // 添加字段
          title: this.$i18n.t("label.addfield"),
          istabimg: false,
          imgurl: "#icon-insert",
          changeimgurl: "#icon-insertblue",
        },
        {
          name: "Template",
          // 选择模板
          title: this.$i18n.t("label.select.template"),
          istabimg: false,
          imgurl: "#icon-shezhi_02",
          changeimgurl: "#icon-shezhi_02_blue",
        },
        {
          name: "signature",
          // 编辑签名
          title: this.$i18n.t("label.emailobject.signature"),
          istabimg: false,
          imgurl: "#icon-editor",
          changeimgurl: "#icon-editorblue",
        },
      ],
      relateobj: "",
      showRelateid: "",
      textarea: "",
      isText: "false",
    };
  },
  components: {
    // updateTemplate,
    addEmailFields,
    relaventSelect,
    emailSign,
    // Multiselect,
    wangeditor,
    addFiles,
    emailTemplateFront,
  },
  watch: {
    editorText(val) {
      this.editorText = val;
    },
  },
  mounted() {
    // 写邮件
    this.getsign();
    this.$Bus.$on("deliver-change-sign",this.ebFn1);
    this.$Bus.$on("deliver-email-sign",this.ebFn2);
    this.getUserInfo();
    this.$Bus.$on("deliver-insert-fields", this.ebFn3);
    this.$Bus.$on("hiddle-template", this.ebFn4);
    // this.$Bus.$on("deliver-new-template", (res) => {
    //   this.$refs.wangeditor.clearvalue();
    //   this.theme = res.subject;
    //   this.$refs.wangeditor.signContent(res.content + this.emailSignval);
    //   this.toolbarOption[2].istabimg = false;
    // });
    this.$Bus.$on("change-sign-isSHow", this.ebFn5);
    this.$Bus.$on("deliver-relateid",this.ebFn6);
  },
  beforeDestroy() {
    this.$Bus.$off("deliver-change-sign",this.ebFn1);
    this.$Bus.$off("deliver-email-sign",this.ebFn2);
    this.$Bus.$off("deliver-insert-fields", this.ebFn3);
    this.$Bus.$off("hiddle-template", this.ebFn4);
    this.$Bus.$off("change-sign-isSHow", this.ebFn5);
    this.$Bus.$off("deliver-relateid",this.ebFn6);
  },
  methods: {
    ebFn1 (res){
      this.$refs.wangeditor.clearvalue();
      this.$refs.wangeditor.signContent(res);
    },
    ebFn2 (res){
      this.$refs.wangeditor.clearvalue();
      this.$refs.wangeditor.signContent(res);
    },
    ebFn3(res){
      this.insertFields = res;
    },
    ebFn4(){
      this.toolbarOption[2].istabimg = false;
    },
    ebFn5(res){
      this.toolbarOption[3].istabimg = res;
    },
    ebFn6(res){
      this.relateid = res;
    },
    deliverTemplate(items, data) {
      this.theme = data.subject;
      this.isText = items.isText;
      if (items.isText == "false") {
        this.$nextTick(() => {
          this.$refs.wangeditor.clearvalue();
          this.$refs.wangeditor.signContent(
            data ? data.content : "",
            data.content.indexOf("{!") != -1
          );
        });
      } else {
        this.textarea = "";
        this.textarea += data.content;
      }
      // this.$refs.wangeditor.clearvalue();
      // this.theme = result.subject;
      // this.$refs.wangeditor.signContent(result.content,result.content.indexOf("{!") != -1);
    },
    // 打开邮件模板弹窗
    openTemplateBox() {
      this.$refs.emailTemplateFront.showDialog("email");
      this.showRelateid = this.$refs.relaventSelect.showRelateId;
    },
    //清空输入框的值
    clearvalue() {
      this.$refs["Receipter"].$refs.input.value = "";
    },
    clearvalue1() {
      this.$refs["cc"].$refs.input.value = "";
    },
    clearvalue2() {
      this.$refs["bcc"].$refs.input.value = "";
    },
    addtagtest(flag, ref) {
      this.$refs[ref].$refs.input.blur = () => {
        this.countries = [];
        this.addmytag();
      };
    },
    addtagtest1(flag, ref) {
      this.$refs[ref].$refs.input.blur = () => {
        this.countries1 = [];
        this.addmytag1();
      };
    },
    addtagtest2(flag, ref) {
      this.$refs[ref].$refs.input.blur = () => {
        this.countries2 = [];
        this.addmytag2();
      };
    },
    closedailog() {
      this.toolbarOption[0].istabimg = false;
    },
    closedailogFields() {
      this.toolbarOption[1].istabimg = false;
    },
    closeTemplate() {
      this.toolbarOption[2].istabimg = false;
    },
    editorContent(val) {
      this.editorText = val;
    },
    //添加字段取值失败后，继续添加字段
    Continueadd() {
      this.errdialog = false;
      let fieldstr = `<span style="color:red">{${this.insertFields}}</span>`;
      // this.editorText = this.editorText + fieldstr;
      this.$refs.wangeditor.signContent(fieldstr);
    },
    //获取签名
    getsign() {
      Emailobject.queryEmailSign()
        .then((res) => {
          if (res.data.isusing == "true") {
            // this.editorText = res.data.signContent;
            this.emailSignval = res.data.signContent;
            this.$refs.wangeditor.signContent(res.data.signContent);
          }
          //草稿箱回显
          if (this.$route.query.value) {
            let draftsmsg = this.$route.query.value;
            this.theme = draftsmsg.name;
            // this.editorText = draftsmsg.htmlbody;
            this.$refs.wangeditor.signContent(draftsmsg.htmlbody);
          }
          //转发回显
          if (this.$route.query.emailvalue) {
            let emailvalue = this.$route.query.emailvalue;
            this.theme = emailvalue.detail.name;
            this.$refs.wangeditor.signContent(emailvalue.detail.htmlbody);
            // this.editorText = emailvalue.detail.htmlbody;
            if (emailvalue.attachment) {
              let filearr = [];
              emailvalue.attachment.forEach((item) => {
                let obj = {};
                obj.id = item.file_info_id;
                obj.name = item.file_name;
                filearr.push(obj);
              });
              this.enclosurearr = filearr;
            }
            if (emailvalue.relate) {
              this.$Bus.$emit("component-Set-val", emailvalue.relate);
              this.relateid = emailvalue.detail.relateid;
            }
          }
          //回复回显
          if (this.$route.query.replyvalue) {
            let emailvalue = this.$route.query.replyvalue;
            //回复 label.emailobject.emaildetail.button.reply
            //密送人 vue_label_email_cc
            //主题 component.sendmail.label.subject
            this.theme =
              this.$i18n.t("label.emailobject.emaildetail.button.reply") +
              `:` +
              emailvalue.detail.name;
            // this.editorText = emailvalue.detail.htmlbody;
            let val = this.$route.query.replyvalue;
            let MessageBody = `
       <div>
        </br>
        </br>
        </br>
        </br>
        <div
          contenteditable="true"
          style="width: 100%;text-align:left;"
        >
          <p>-------------------------------------------------------</p>
          <p>${this.$i18n.t("label.senduser")}:${val.detail.fromaddress}</p>
          <p>${this.$i18n.t("component.sendmail.label.touser")}:${
              val.detail.toaddress
            }</p>
          <p ${val.detail.ccaddress === "" ? false : true}>${this.$i18n.t(
              "label.mobile.phone.cc.people"
            )}:${val.detail.ccaddress}</p>
          <p>${this.$i18n.t("vue_label_email_cc")}:${
              val.detail.bccaddress === "" ||
              val.detail.bccaddress === undefined
                ? ""
                : val.detail.bccaddress
            }</p>
          <p>${this.$i18n.t("component.sendmail.label.subject")}:${
              val.detail.name
            }</p>
          <div style="letter-spacing: 0px;
    text-align: justify;
    white-space: pre-line">${val.detail.htmlbody}</div>
        </div>
      </div>`;
            this.$refs.wangeditor.signContent(MessageBody);
            this.seahcvalue.push(emailvalue.detail.fromaddress);
            if (emailvalue.attachment) {
              let filearr = [];
              emailvalue.attachment.forEach((item) => {
                let obj = {};
                obj.id = item.file_info_id;
                obj.name = item.file_name;
                filearr.push(obj);
              });
              this.enclosurearr = filearr;
            }
            if (emailvalue.relate) {
              this.$Bus.$emit("component-Set-val", emailvalue.relate);
              this.relateid = emailvalue.detail.relateid;
            }
          }
          //全部回复回显
          if (this.$route.query.replyvalues && this.$route.query.alladdressee) {
            let emailvalue = this.$route.query.replyvalues;
            let alladdressee = this.$route.query.alladdressee;
            let MessageBody = `
       <div>
        </br>
        </br>
        </br>
        </br>
        <div
          contenteditable="true"
          style="width: 100%;text-align:left;"
        >
          <p>-------------------------------------------------------</p>
          <p>${this.$i18n.t("label.senduser")}:${
              emailvalue.detail.fromaddress
            }</p>
          <p>${this.$i18n.t("component.sendmail.label.touser")}:${
              emailvalue.detail.toaddress
            }</p>
          <p ${
            emailvalue.detail.ccaddress === "" ? false : true
          }>${this.$i18n.t("label.mobile.phone.cc.people")}:${
              emailvalue.detail.ccaddress
            }</p>
          <p>${this.$i18n.t("vue_label_email_cc")}:${
              emailvalue.detail.bccaddress === "" ||
              emailvalue.detail.bccaddress === undefined
                ? ""
                : emailvalue.detail.bccaddress
            }</p>
          <p>${this.$i18n.t("component.sendmail.label.subject")}:${
              emailvalue.detail.name
            }</p>
          <div style="letter-spacing: 0px;
    text-align: justify;
    white-space: pre-line">${emailvalue.detail.htmlbody}</div>
        </div>
      </div>`;
            this.theme =
              this.$i18n.t("label.emailobject.emaildetail.button.reply") +
              `:` +
              emailvalue.detail.name;
            // this.editorText = emailvalue.detail.htmlbody;
            this.$refs.wangeditor.signContent(MessageBody);
            alladdressee.forEach((item) => {
              this.seahcvalue.push(item.email);
            });
            if (emailvalue.attachment) {
              let filearr = [];
              emailvalue.attachment.forEach((item) => {
                let obj = {};
                obj.id = item.file_info_id;
                obj.name = item.file_name;
                filearr.push(obj);
              });
              this.enclosurearr = filearr;
            }
            if (emailvalue.relate) {
              this.$Bus.$emit("component-Set-val", emailvalue.relate);
              this.relateid = emailvalue.detail.relateid;
            }
          }
        })
        .catch(() => {});
    },
    //删除附件方法
    deletefile(index) {
      this.enclosurearr.splice(index, 1);
    },
    //添加附件
    addenclosure(params) {
      this.toolbarOption[0].istabimg = false;
      params.forEach((item) => {
        this.enclosurearr.push(item);
      });
    },
    //手动填入邮箱
    addmytag() {
      // let reg =
      //   /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
      let reg = /^[^](.*[^])*@[^].*\..*[^]$/;
      let newReg = /^[^@]*@[^@]*$/;
      if (this.$refs["Receipter"].$refs.input.value) {
        if (reg.test(this.queryvalue) && newReg.test(this.queryvalue)) {
          let istrue = this.seahcvalue.some((item) => {
            return item.name == this.queryvalue;
          });
          if (istrue == false) {
            // let obj = {};
            // obj.name = this.queryvalue;
            // obj.value = this.queryvalue;
            // obj.id = this.queryvalue;
            // obj.objid = this.queryvalue;
            // obj.label = this.queryvalue;
            this.seahcvalue.push(this.queryvalue);
          } else {
            // 该邮箱已添加
            this.$message({
              showClose: true,
              message: this.$i18n.t("vue_label_email_benn_added"),
              type: "error",
            });
          }
        } else {
          this.$message({
            showClose: true,
            // 请输入正确的电子邮箱地址
            message: this.$i18n.t("label_emailtocloudcc_redmessage1"),
            type: "error",
          });
        }
      }
    },
    //抄送手动填入邮箱
    addmytag1() {
      let reg = /^[^](.*[^])*@[^].*\..*[^]$/;
      let newReg = /^[^@]*@[^@]*$/;
      if (this.$refs["cc"].$refs.input.value) {
        if (reg.test(this.queryvalue1) && newReg.test(this.queryvalue1)) {
          let istrue = this.cc.some((item) => {
            return item.name == this.queryvalue1;
          });
          if (istrue == false) {
            // let obj = {};
            // obj.name = this.queryvalue1;
            // obj.value = this.queryvalue1;
            // obj.id = this.queryvalue1;
            // obj.objid = this.queryvalue1;
            // this.cc.push(obj);
            // this.$refs.multiselect1.search = "";
            this.cc.push(this.queryvalue1);
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("vue_label_email_benn_added"),
              type: "error",
            });
          }
        } else {
          this.$message({
            showClose: true,
            // 请输入正确的电子邮箱地址
            message: this.$i18n.t("label_emailtocloudcc_redmessage1"),
            type: "error",
          });
        }
      }
    },
    //密送手动填入邮箱
    addmytag2() {
      let reg = /^[^](.*[^])*@[^].*\..*[^]$/;
      let newReg = /^[^@]*@[^@]*$/;
      if (this.$refs["bcc"].$refs.input.value) {
        if (reg.test(this.queryvalue2) && newReg.test(this.queryvalue2)) {
          let istrue = this.bcc.some((item) => {
            return item.name == this.queryvalue2;
          });
          if (istrue == false) {
            // let obj = {};
            // obj.name = this.queryvalue2;
            // obj.value = this.queryvalue2;
            // obj.id = this.queryvalue2;
            // obj.objid = this.queryvalue2;
            // this.bcc.push(obj);
            // this.$refs.multiselect2.search = "";
            this.bcc.push(this.queryvalue2);
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("vue_label_email_benn_added"),
              type: "error",
            });
          }
        } else {
          this.$message({
            showClose: true,
            // 请输入正确的电子邮箱地址
            message: this.$i18n.t("label_emailtocloudcc_redmessage1"),
            type: "error",
          });
        }
      }
    },

    //搜索收件人列表
    asyncFind(query) {
      this.queryvalue = query;
      let str = query.replace(/'/g, "");
      Emailobject.seachemail({
        searchKey: str,
      })
        .then((res) => {
          if (res.data != null) {
            let peoplearr = [];
            res.data.forEach((item) => {
              let obj = {};
              obj.name = item.email;
              obj.value = item.name;
              obj.id = item.id;
              obj.objid = item.objid;
              obj.label = item.name + `<${item.email}>`;
              if (item.objid == "lead") {
                obj.src = "";
              }
              peoplearr.push(obj);
            });
            this.countries = peoplearr;
          } else {
            this.countries = [];
          }
        })
        .catch({});
    },
    //抄送搜索收件人列表
    asyncFind1(query) {
      this.queryvalue1 = query;
      let str = query.replace(/'/g, "");
      Emailobject.seachemail({
        searchKey: str,
      })
        .then((res) => {
          if (res.data != null) {
            let peoplearr = [];
            res.data.forEach((item) => {
              let obj = {};
              obj.name = item.email;
              obj.value = item.name;
              obj.id = item.id;
              obj.objid = item.objid;
              peoplearr.push(obj);
            });
            this.countries1 = peoplearr;
          } else {
            this.countries1 = [];
          }
        })
        .catch({});
    },
    //密送搜索收件人列表
    asyncFind2(query) {
      this.queryvalue2 = query;
      let str = query.replace(/'/g, "");
      Emailobject.seachemail({
        searchKey: str,
      })
        .then((res) => {
          if (res.data != null) {
            let peoplearr = [];
            res.data.forEach((item) => {
              let obj = {};
              obj.name = item.email;
              obj.value = item.name;
              obj.id = item.id;
              obj.objid = item.objid;
              peoplearr.push(obj);
            });
            this.countries2 = peoplearr;
          } else {
            this.countries2 = [];
          }
        })
        .catch({});
    },
    //插入字段
    async insertFieldsMethod() {
      if (this.insertFields == "") {
        //请选择插入字段
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("vue_label_email_insert_field"),
        });
        return false;
      }
      if (this.relateid) {
        let result = await Emailobject.insertFields({
          filedstr: `{${this.insertFields}}`,
          recordId: this.relateid,
        });
        if (result.data == null) {
          this.errdialog = true;
        } else {
          this.$refs.wangeditor.signContent(result.data);
        }
      } else {
        this.errdialog = true;
      }
      this.toolbarOption[1].istabimg = false;
    },
    //获取当前登录人信息
    getUserInfo() {
      Emailobject.getUserInfo()
        .then((res) => {
          this.personalemailmsg = res.data;
        })
    },
    async sendEmail(status) {
      //拼接收件人邮箱
      let getEmailId;
      if (this.seahcvalue.length != 0) {
        this.seahcvalue.forEach((item) => {
          if (item.id) {
            this.userList.push(item.name);
          } else {
            this.userList.push(item);
          }
        });
        getEmailId = this.userList.join(";");
      }
      //拼接关联记录id(潜在客户或联系人1)
      let relatedidlist = [];
      let relatedids;
      if (this.seahcvalue.length != 0) {
        this.seahcvalue.forEach((item) => {
          if (item.objid == "lead" || item.objid == "contact") {
            relatedidlist.push(item.id);
          }
        });
        relatedids = relatedidlist.join(",");
      }
      //拼接抄送人邮箱
      let cclist = [];
      let ccliststr;
      if (this.cc.length != 0) {
        this.cc.forEach((val) => {
          if (val.id) {
            cclist.push(val.name);
          } else {
            cclist.push(val);
          }
        });
        ccliststr = cclist.join(";");
      } else {
        ccliststr = "";
      }
      //拼接密送人邮箱
      let bcclist = [];
      let bccliststr;
      if (this.bcc.length != 0) {
        this.bcc.forEach((value) => {
          if (value.id) {
            bcclist.push(value.name);
          } else {
            bcclist.push(value);
          }
        });
        bccliststr = bcclist.join(";");
      } else {
        bccliststr = "";
      }
      //拼接文件id
      let filelist = [];
      let havefile;
      let filedstr;
      if (this.enclosurearr.length != 0) {
        this.enclosurearr.forEach((fileval) => {
          filelist.push(fileval.fileContentId);
        });
        filedstr = filelist.join(",");
        havefile = "1";
      } else {
        filedstr = "";
        havefile = "0";
      }
      //邮件发送状态码
      let savetempnum;
      let singleSendnum;
      let messageval;
      if (status == "0") {
        savetempnum = "1";
        singleSendnum = "";
        // 保存成功
        messageval = this.$i18n.t("label.search.saveok");
      } else if (status == "1") {
        savetempnum = "";
        singleSendnum = "";
        // 发送邮件成功
        messageval = this.$i18n.t("label.emailobject.emailsent");
      } else if (status == "2") {
        savetempnum = "";
        singleSendnum = "1";
        //分别发送邮件成功
        messageval = this.$i18n.t("vue_label_email_send_emails_separately");
      }
      //表单验证
      if (this.userList.length == 0 && status != "0") {
        this.$message({
          showClose: true,
          type: "warning",
          // 请选择收件人
          message: this.$i18n.t("label.email.recipients.select"),
        });
        return false;
      }
      if (this.theme == "") {
        this.$message({
          showClose: true,
          type: "warning",
          // 请输入邮件主题
          message: this.$i18n.t("vue_label_email_please_enter_email_subject"),
        });
        return false;
      }
      if (this.editorText == "") {
        //请输入邮件内容
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t(
            "vue_label_email_please_enter_the_message_content"
          ),
        });
        return false;
      }
      let option = {
        subject: this.theme,
        toaddress: getEmailId,
        ccaddress: ccliststr,
        bccaddress: bccliststr,
        content: this.editorText,
        isattachment: havefile,
        attachment: filedstr,
        istrackopen: this.eyes.istabimg == true ? "1" : "0",
        relateid: this.relateid,
        relatedid: relatedids,
        savetemp: savetempnum,
        singleSend: singleSendnum,
      };
      if (this.isText == "true") {
        option.content = this.textarea;
      }
      this.sendloading = true;
      let result = await Emailobject.sendemail(option);
      if (result.result == true && result.returnInfo == null) {
        this.sendloading = false;
        this.$message({
          showClose: true,
          type: "success",
          message: messageval,
        });
        this.theme = "";
        this.editorText = "";
        this.textarea = "";
        this.isText = "false";
        this.isBatch = false;
        this.userList = [];
        cclist = [];
        bcclist = [];
        this.seahcvalue = [];
        this.cc = [];
        this.bcc = [];
        this.enclosurearr = [];
        relatedids = [];
        relatedidlist = [];
        this.$refs.wangeditor.clearvalue();
      } else {
        this.sendloading = false;
        this.$message.error(result.returnInfo);
      }
    },
    //切换选项卡
    tabeyescolor() {
      this.eyes.istabimg = !this.eyes.istabimg;
    },
    //富文本编辑器
    onContentChange(val) {
      this.editorText = val;
    },
    afterChange() {},
    //工具栏
    tabtoolbar(name) {
      if (name == "signature") {
        this.$Bus.$emit("deliver-signature", true);
      }
      if (name == "Template") {
        this.openTemplateBox();
      }
      this.toolbarOption.forEach((item) => {
        //判断是否为邮件追踪项
        if (item.name == name && item.name != "eyes") {
          item.istabimg = true;
        } else {
          item.istabimg = false;
        }
        //邮件追踪项则切换选中状态
        if (item.name == "eyes") {
          item.istabimg = !item.istabimg;
        }
        if (item.name == "enclosure") {
          this.$refs.atoLib.initPage();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-multiselect {
  ::v-deep .el-select {
    width: 100%;
  }
}
::v-deep .w-e-toolbar {
  z-index: 2 !important;
}
::v-deep .w-e-text-container {
  z-index: 1;
}
::v-deep .multiselect__option--highlight {
  background: #1890ff;
  outline: none;
  color: #fff;
}
::v-deep .multiselect__element:hover {
  background-color: #fff;
}

::v-deep .multiselect__tags {
  padding: 8px 127px 0 8px;
  // min-height: 30px !important;
  border-radius: 5px !important;
}

::v-deep .multiselect__select {
  display: none;
}

::v-deep .multiselect__placeholder {
  display: inline;
  margin-bottom: 0px;
  padding-top: 2px;
}

::v-deep .multiselect__tag {
  font-size: 14px;
  color: #080707;
  background-color: rgb(255, 255, 255) !important;
  border: 1px solid rgb(221, 219, 218);

  .multiselect__tag-icon:after {
    color: rgb(112, 110, 107) !important;
  }

  .multiselect__tag-icon:hover {
    background-color: transparent !important;
  }
}
ul {
  margin: 0;
}
.addfile {
  ::v-deep .el-dialog__body {
    padding: 0 !important;
  }

  .dialog-footer {
    display: flex;
    justify-content: space-between;
    .chiocenumber {
      font-size: 12px;
      color: #666666;
    }
  }
}
.files {
  width: 100%;
  margin-top: 10px;
  ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    li:nth-child(4n) {
      margin-left: 0;
    }
    li {
      width: 24%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f3f2f2;
      padding: 15px 10px;
      border-radius: 3px;
      margin-bottom: 10px;
      margin-left: 13px;
      .enclosurebox {
        display: flex;
        align-items: center;
      }
      span {
        font-size: 14px;
        color: #006dcc;
        margin-right: 10px;
        max-width: 140px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      i {
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .enclosureicon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.writeemailmain {
  display: flex;
  height: 100%;
}
.writeemailleft {
  width: 100%;
  padding: 18px 10px;
  .Receipter {
    display: flex;
    align-items: center;
    position: relative;
    margin: 15px 0 0 0;
    ::v-deep .el-input__inner {
      padding: 0 90px 0 15px;
    }
    ::v-deep .el-select__input {
      padding-right: 55px;
    }
    .bcc {
      position: absolute;
      top: 10px;
      right: 13px;
      z-index: 999;
      span {
        margin-left: 10px;
        cursor: pointer;
        color: #006dcc;
      }
    }

    .Receipternumber {
      margin: 15px 0 0 10px;
    }
  }
  .Receiptertext {
    white-space: nowrap;
    display: flex;
    align-items: center;
    margin-top: 15px;
    height: 40px;
    line-height: 40px;
  }
  .themename {
    display: flex;
    align-items: center;
    white-space: nowrap;
    span:first-child {
      color: #fff;
    }
    span {
      margin: 15px 0 0 0;
    }
    ::v-deep .el-input {
      margin: 15px 0 0 0;
    }
  }
  .editbox {
    display: flex;
    margin-top: 15px;
    span {
      white-space: nowrap;
    }
    span:first-child {
      color: #fff;
    }
    .edit {
      margin-left: 25px;
    }
  }
  .toolbar {
    margin: 63px 0 10px 0;
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      li {
        border: 1px solid #dcdcdc;
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;
        .toolbaricon {
          width: 15px;
          height: 15px;
          display: flex;
          align-items: center;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  .subbotton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.writeemailright {
  padding: 18px 10px;
  border-left: 1px solid #dcdcdc;
}
</style>
